import React from "react";
import {
  Image,
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Linking
} from "react-native";
import Hoverable from "./Hoverable";
import { FaPhoneSquare } from "react-icons/fa";
import Scroll from "react-scroll";

const Link = props => (
  <Text {...props} style={StyleSheet.compose(styles.link, props.style)} />
);

const styles = StyleSheet.create({
  text: {
    flex: 1,
    fontSize: 14,
    textAlign: "center",
    textTransform: "uppercase"
  },
  link: {
    color: "white"
  },
  code: {
    fontFamily: "monospace, monospace"
  },
  switch: {
    position: "absolute",
    top: "10%",
    left: "10%",
    zIndex: 4
  },
  button: {
    flex: 1,
    paddingVertical: 10
  },
  menuStyle: {
    flex: 1,
    flexDirection: "row"
  },
  menuBarStyle: {
    position: "absolute",
    top: 0,
    left: 0,
    backgroundColor: "rgba(0,0,0,0.7)",
    zIndex: 3
  },
  menuLogoStyle: {
    flex: 1,
    justifyContent: "center",
    marginHorizontal: "5%"
  },
  menuLinkStyle: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    marginRight: "5%"
  },
  linkHover: {
    textDecorationLine: "underline"
  },
  image: {
    width: 120,
    height: 46
  },
  imageMobile: {
    width: 89,
    height: 34
  }
});

export default function Header({ width, mobile }) {
  return (
    <View style={[styles.menuBarStyle, { width, height: 80 }]}>
      <View style={styles.menuStyle}>
        <View style={styles.menuLogoStyle}>
          <Image
            source={require("./logo5.png")}
            resizeMode="cover"
            style={[styles.image, mobile && styles.imageMobile]}
          />
        </View>
        <View style={[styles.menuLinkStyle]}>
          <Hoverable onHoverIn={null} onHoverOut={null}>
            {isHovered => (
              <Text
                style={[
                  styles.text,
                  isHovered && styles.linkHover,
                  mobile && { display: "none" }
                ]}
              >
                {" "}
                <Link style={styles.code}>
                  <Scroll.Link
                    to="gallery"
                    spy={true}
                    smooth={true}
                    duration={500}
                  >
                    галерея
                  </Scroll.Link>
                </Link>
              </Text>
            )}
          </Hoverable>
          <Hoverable onHoverIn={null} onHoverOut={null}>
            {isHovered => (
              <Text
                style={[
                  styles.text,
                  isHovered && styles.linkHover,
                  mobile && { display: "none" }
                ]}
              >
                {" "}
                <Link style={styles.code}>
                  <Scroll.Link
                    to="video"
                    spy={true}
                    smooth={true}
                    duration={500}
                  >
                    видео
                  </Scroll.Link>
                </Link>
              </Text>
            )}
          </Hoverable>
          <Hoverable onHoverIn={null} onHoverOut={null}>
            {isHovered => (
              <Text
                style={[
                  styles.text,
                  isHovered && styles.linkHover,
                  mobile && { display: "none" }
                ]}
              >
                {" "}
                <Link style={styles.code}>
                  <Scroll.Link
                    to="contacts"
                    spy={true}
                    smooth={true}
                    duration={500}
                  >
                    контакты
                  </Scroll.Link>
                </Link>
              </Text>
            )}
          </Hoverable>
          <View>
            <TouchableOpacity
              onPress={() => {
                Linking.openURL("tel:+7-708-999-07-70");
              }}
            >
              <View
                style={{
                  flex: 1,
                  flexDirection: "row",
                  padding: 5,
                  backgroundColor: "coral",
                  color: "white"
                }}
              >
                <FaPhoneSquare />
                <Text
                  style={[
                    styles.text,
                    {
                      backgroundColor: "coral",
                      color: "white",
                      paddingHorizontal: 10
                    }
                  ]}
                >
                  +7 708 999 07 70
                </Text>
              </View>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </View>
  );
}
