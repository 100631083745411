import React from "react";
import { StyleSheet, Text, View } from "react-native";
import Hoverable from "./Hoverable";
import { FaPhoneSquare, FaMailBulk, FaSearchLocation } from "react-icons/fa";

const Link = props => (
  <Text
    {...props}
    accessibilityRole="link"
    style={StyleSheet.compose(styles.link, props.style)}
  />
);

const styles = StyleSheet.create({
  contaner: {
    flex: 1,
    backgroundColor: "rgba(0,0,0,0.9)",
    flexDirection: "row"
  },
  address: {
    flex: 1,
    padding: "5%"
  },
  map: {
    flex: 1,
    alignItems: "center",
    padding: 10
  },
  textLabel: {
    flex: 1,
    color: "white",
    fontSize: 24,
    textAlign: "left"
  },
  text: {
    flex: 2,
    color: "white",
    fontSize: 24,
    textAlign: "left"
  },
  contactsContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center"
  },
  icon: {
    fontSize: 24,
    color: "white",
    padding: 10
  },
  linkHover: {
    textDecorationLine: "underline"
  },
  code: {
    fontFamily: "monospace, monospace"
  }
});

export default function Contacts({ width, mobile }) {
  let frameWidth = mobile ? width : 400;
  let frameHeight = mobile ? width * 0.75 : 300;
  return (
    <View style={[styles.contaner, mobile && { flexDirection: "column" }]}>
      <View style={styles.address}>
        <View style={styles.contactsContainer}>
          <View style={[styles.icon, mobile && { fontSize: 14 }]}>
            <FaPhoneSquare />
          </View>
          <Text style={[styles.textLabel, mobile && { fontSize: 14 }]}>
            Телефон:
          </Text>
          <Hoverable onHoverIn={null} onHoverOut={null}>
            {isHovered => (
              <Text
                style={[
                  styles.text,
                  isHovered && styles.linkHover,
                  mobile && { fontSize: 14 }
                ]}
              >
                {" "}
                <Link href="tel:+7-708-999-07-70" style={styles.code}>
                  +7-708-999-07-70
                </Link>
              </Text>
            )}
          </Hoverable>
        </View>
        <View style={styles.contactsContainer}>
          <View style={[styles.icon, mobile && { fontSize: 14 }]}>
            <FaMailBulk />
          </View>

             </View>
        <View style={styles.contactsContainer}>
          <View style={[styles.icon, mobile && { fontSize: 14 }]}>
            <FaSearchLocation />
          </View>

          <Text style={[styles.textLabel, mobile && { fontSize: 14 }]}>
            Адрес:
          </Text>
          <Text style={[styles.text, mobile && { fontSize: 14 }]}>
            г. Алматы.
          </Text>
        </View>
      </View>
      <View style={[styles.map]}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2907.6065640748875!2d76.88062304874028!3d43.2177407847996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x388368edec098ce7%3A0x8ef4e227fce3fd0e!2sAvtozavod.kz!5e0!3m2!1sru!2sru!4v1483974747130"
          width={frameWidth}
          height={frameHeight}
          frameBorder="0"
          allowFullScreen=""
        ></iframe>
      </View>
    </View>
  );
}
