import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  ImageBackground,
  Animated,
  TouchableOpacity
} from "react-native";

import { FaPowerOff } from "react-icons/fa";

const styles = StyleSheet.create({
  textOnImgContainer: {
    position: "absolute",
    top: "50%",
    left: "5%",
    zIndex: 0
  },
  smallTextOnImg: {
    color: "white",
    fontSize: 30,
    fontWeight: 400,
    textTransform: "uppercase",
    fontFamily: "Roboto Slab"
  },
  smallTextOnImgMobile: {
    color: "white",
    fontSize: 14,
    fontWeight: 400,
    textTransform: "uppercase",
    fontFamily: "Roboto Slab"
  },
  textOnImgStyle: {
    color: "white",
    fontSize: 40,
    fontWeight: 400,
    textTransform: "uppercase",
    fontFamily: "Roboto Slab"
  },
  textOnImgStyleMobile: {
    color: "white",
    fontSize: 20,
    fontWeight: 400,
    textTransform: "uppercase",
    fontFamily: "Roboto Slab"
  },
  imageTintContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 2,
    overflow: "hidden"
  },
  imageTint: {
    maxWidth: 1920,
    maxHeight: 805
  },
  imageTintMobile: {
    width: 800,
    height: 300
  },
  switch: {
    position: "absolute",
    top: "30%",
    left: "5%",
    zIndex: 4
  },
  switchText: { fontSize: 24, color: "white", textAlign: "center" }
});

const DATA = [
  {
    textUp: "Прекрасное решение для вашей",
    textMid: "конфиденциа́льной",
    textDown: "жизни"
  },
  {
    textUp: "Защита панелей салона от",
    textMid: "черезмерного",
    textDown: "нагрева"
  },
  {
    textUp: "Сокращение опасных для кожи",
    textMid: "Ультрафиолетовых",
    textDown: "лучей"
  }
];

export default function CarComponent({ width, mobile }) {
  const [isActive, setIsActive] = useState(false);
  let textAnim = new Animated.Value(0);
  const [fadeAnim, setFadeAnim] = useState(new Animated.Value(0));
  const [textNumber, setTextNumber] = useState(0);

  useEffect(() => {
    startTextAnimation();
  }, [textNumber]);

  const tintCarWindowsColor = () => {
    let value = isActive ? 0 : 1;
    Animated.timing(fadeAnim, {
      toValue: value
    }).start(setIsActive(!isActive));
  };

  const startTextAnimation = () => {
    Animated.sequence([
      Animated.timing(textAnim, {
        duration: 2000,
        toValue: 1
      }),
      Animated.timing(textAnim, {
        duration: 1000,
        delay: 10000,
        toValue: 2
      })
    ]).start(() => {
      if (textNumber !== 2) {
        setTextNumber(textNumber + 1);
      } else setTextNumber(0);
    });
  };

  let opacity = textAnim.interpolate({
    inputRange: [0, 1, 2],
    outputRange: [0, 1, 0] // 0 : 150, 0.5 : 75, 1 : 0
  });

  return (
    <>
      <Animated.View style={[styles.imageTintContainer, { width }]}>
        <Animated.Image
          source={require("./bmw_tint.png")}
          resizeMode="cover"
          style={[
            styles.imageTint,
            { width, height: width * 0.4, opacity: fadeAnim },
            mobile && { height: 400 }
          ]}
        />
        <View style={styles.switch}>
          <TouchableOpacity onPress={tintCarWindowsColor}>
            <View
              style={{
                flex: 1,
                flexDirection: "row",
                justifyContent: "center",
                backgroundColor: "rgba(0,0,0,0.2)",
                padding: 10
              }}
            >
              <View
                style={[
                  {
                    fontSize: 24,
                    padding: 5,
                    backgroundColor: "red",
                    color: "white",
                    marginRight: 10,
                    borderRadius: 24
                  },
                  isActive && { backgroundColor: "rgba(0,255,0, 1)" },
                  mobile && { fontSize: 14, borderRadius: 12 }
                ]}
              >
                <FaPowerOff />
              </View>
              <Text style={[styles.switchText, mobile && { fontSize: 14 }]}>
                {isActive ? "Выключить" : "Включить"}
              </Text>
            </View>
          </TouchableOpacity>
        </View>
        <View style={styles.textOnImgContainer} pointerEvents={"none"}>
          <Animated.Text
            style={[
              styles.smallTextOnImg,
              {
                transform: [
                  {
                    translateX: textAnim.interpolate({
                      inputRange: [0, 1, 2],
                      outputRange: [30, 0, -500] // 0 : 150, 0.5 : 75, 1 : 0
                    })
                  }
                ],
                opacity
              },
              mobile && styles.smallTextOnImgMobile
            ]}
          >
            {DATA[textNumber].textUp}
          </Animated.Text>
          <Animated.Text
            numberOfLines={1}
            adjustsFontSizeToFit={true}
            style={[
              styles.textOnImgStyle,
              {
                transform: [
                  {
                    translateX: textAnim.interpolate({
                      inputRange: [0, 1, 2],
                      outputRange: [100, 0, -700] // 0 : 150, 0.5 : 75, 1 : 0
                    })
                  }
                  /*{
                      rotateY: this.state.fadeAnim.interpolate({
                        inputRange: [0, 1],
                        outputRange: ["90deg", "0deg"] // 0 : 150, 0.5 : 75, 1 : 0
                      })
                    }*/
                ],
                opacity
              },
              mobile && styles.textOnImgStyleMobile
            ]}
          >
            {DATA[textNumber].textMid}
          </Animated.Text>
          <Animated.Text
            style={[
              styles.smallTextOnImg,
              {
                transform: [
                  {
                    translateX: textAnim.interpolate({
                      inputRange: [0, 1, 2],
                      outputRange: [30, 0, -500] // 0 : 150, 0.5 : 75, 1 : 0
                    })
                  }
                ],
                opacity
              },
              mobile && styles.smallTextOnImgMobile
            ]}
          >
            {DATA[textNumber].textDown}
          </Animated.Text>
        </View>
      </Animated.View>
      <ImageBackground
        source={require("./bmw_bg.jpg")}
        resizeMode="cover"
        style={[
          styles.imageTint,
          { width, height: width * 0.4 },
          mobile && { height: 400 }
        ]}
      />
    </>
  );
}
