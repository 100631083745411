import React from "react";
import { StyleSheet, Text, View } from "react-native";
import {
  FaRegWindowRestore,
  FaRegRegistered,
  FaVolumeDown,
  FaTools,
  FaTape,
  FaCar,
  FaRegCalendarCheck
} from "react-icons/fa";
import {
  MdSettingsRemote,
  MdTimer,
  MdWbSunny,
  MdSecurity,
  MdAttachMoney
} from "react-icons/md";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "white",
    padding: 10
  },
  textItem: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: 10
  },
  text: {
    fontSize: 18,
    //textAlign: "left",
    color: "black"
    //textTransform: "uppercase"
  },
  titleContainer: {
    flex: 1
  },
  title: {
    fontSize: 34,
    fontFamily: "Roboto Slab",
    textAlign: "center",
    color: "black"
  },
  textContainer: {
    flex: 5,
    justifyContent: "center",
    paddingHorizontal: 5
  },
  iconContainer: {
    flex: 1,
    paddingHorizontal: 5
  },
  icon: {
    fontSize: 34,
    color: "coral",
    justifyContent: "center",
    alignItems: "center",
    padding: 10
  },
  columnContainer: {
    flex: 1,
    flexDirection: "row"
  },
  leftBlock: {
    flex: 1
  },
  centerBlock: {
    flex: 1
  },
  rightBlock: {
    flex: 1
  }
});

export default function TextComponent({ width, mobile }) {
  return (
    <View style={[styles.container, mobile && { padding: 0 }]}>
      <View style={styles.titleContainer}>
        <Text style={[styles.title, mobile && { fontSize: 24 }]}>
          Автоматическая электро тонировка стекол.
        </Text>
      </View>
      <View
        style={[styles.columnContainer, mobile && { flexDirection: "column" }]}
      >
        <View style={styles.leftBlock}>
          <View style={styles.textItem}>
            <View style={styles.iconContainer}>
              <View style={styles.icon}>
                <FaRegWindowRestore />
              </View>
            </View>
            <View style={styles.textContainer}>
              <Text style={styles.text}>
                По сути это двойное Стекло, внутри которого находится
                специальный полимер (гель), который имеет свойство менять
                тональность.
              </Text>
            </View>
          </View>
          <View style={styles.textItem}>
            <View style={styles.iconContainer}>
              <View style={styles.icon}>
                <FaRegRegistered />
              </View>
            </View>
            <View style={styles.textContainer}>
              <Text style={styles.text}>
                Это уникальный продукт, так как в отличии от китайских и
                американских аналогов в тонированном состоянии он остаётся
                абсолютно прозрачным.
              </Text>
            </View>
          </View>
          <View style={styles.textItem}>
            <View style={styles.iconContainer}>
              <View style={styles.icon}>
                <FaVolumeDown />
              </View>
            </View>
            <View style={styles.textContainer}>
              <Text style={styles.text}>
                За счет двойного остекления в авто станет тише, так как это
                является дополнительной шумоизоляцией.
              </Text>
            </View>
          </View>
          <View style={styles.textItem}>
            <View style={styles.iconContainer}>
              <View style={styles.icon}>
                <FaTape />
              </View>
            </View>
            <View style={styles.textContainer}>
              <Text style={styles.text}>
                Новые Стёкла имеют абсолютно точную форму с заводскими стёклами.
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.centerBlock}>
          <View style={styles.textItem}>
            <View style={styles.iconContainer}>
              <View style={styles.icon}>
                <FaCar />
              </View>
            </View>
            <View style={styles.textContainer}>
              <Text style={styles.text}>
                Все стёкла изготавливаются на заказ под любую марку авто.
              </Text>
            </View>
          </View>
          <View style={styles.textItem}>
            <View style={styles.iconContainer}>
              <View style={styles.icon}>
                <FaRegCalendarCheck />
              </View>
            </View>
            <View style={styles.textContainer}>
              <Text style={styles.text}>Если комплекта нет в наличии, срок изготовления 3-4 недели.</Text>
            </View>
          </View>
          <View style={styles.textItem}>
            <View style={styles.iconContainer}>
              <View style={styles.icon}>
                <MdSettingsRemote />
              </View>
            </View>
            <View style={styles.textContainer}>
              <Text style={styles.text}>
                Включается дистанционно пультом управления
              </Text>
            </View>
          </View>
          <View style={styles.textItem}>
            <View style={styles.iconContainer}>
              <View style={styles.icon}>
                <MdTimer />
              </View>
            </View>
            <View style={styles.textContainer}>
              <Text style={styles.text}>
                Время затемнения/осветления состaвляет около минуты.
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.rightBlock}>
          <View style={styles.textItem}>
            <View style={styles.iconContainer}>
              <View style={styles.icon}>
                <MdWbSunny />
              </View>
            </View>
            <View style={styles.textContainer}>
              <Text style={styles.text}>
                В выключенном состоянии Стекло соответствует всем ГОСТам по
                светопропускаемости.
              </Text>
            </View>
          </View>
          <View style={styles.textItem}>
            <View style={styles.iconContainer}>
              <View style={styles.icon}>
                <MdSecurity />
              </View>
            </View>
            <View style={styles.textContainer}>
              <Text style={styles.text}>
                Для дополнительной защиты стёкла покрыты с двух сторон
                бронеплёнкой ClearPlex.
              </Text>
            </View>
          </View>
          <View style={styles.textItem}>
            <View style={styles.iconContainer}>
              <View style={styles.icon}>
                <FaTools />
              </View>
            </View>
            <View style={styles.textContainer}>
              <Text style={styles.text}>
                Установка происходит в нашем установoчном центре и занимает 1-2
                дня в зависимости от автомобиля.
              </Text>
            </View>
          </View>
          <View style={styles.textItem}>
            <View style={styles.iconContainer}>
              <View style={styles.icon}>
                <MdAttachMoney />
              </View>
            </View>
            <View style={styles.textContainer}>
              <Text style={styles.text}>
                Цена за комплект из 2 передних стёкол стартует от 1 500 000 тг.
              </Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
}
