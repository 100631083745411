import React, { Component } from "react";
import { StyleSheet, View, Dimensions } from "react-native";

import Header from "./Header";
import CarComponent from "./CarComponent";
import Gallery from "./Gallery";
import Video from "./Video";
import Contacts from "./Contacts";
import Footer from "./Footer";
import { Element } from "react-scroll";
import TextComponent from "./TextComponent";

class App extends Component {
  state = {
    width: null,
    height: null,
    mobile: null
  };

  componentDidMount() {
    const width = Dimensions.get("window").width;
    this.setState({ width });
  }

  onLayout = ({
    nativeEvent: {
      layout: { x, y, width, height }
    }
  }) => {
    this.setState({ width, height });
    if (width < 750) {
      this.setState({ mobile: true });
    } else this.setState({ mobile: false });
  };

  render() {
    const { width, mobile } = this.state;

    return (
      <View style={[styles.app]} onLayout={this.onLayout}>
        <Header {...{ width, mobile }} />
        <CarComponent {...{ width, mobile }} />
        <TextComponent {...{ width, mobile }} />
        <Element id="gallery">
          <Gallery {...{ width, mobile }} />
        </Element>
        <Element id="video">
          <Video {...{ width, mobile }} />
        </Element>
        <Element id="contacts">
          <Contacts {...{ width, mobile }} />
        </Element>

        <Footer {...{ mobile }} />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  app: {
    flex: 1
  }
});

export default App;
