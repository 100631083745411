import React, { useRef } from "react";
import {
  StyleSheet,
  View,
  Animated,
  TouchableOpacity,
  FlatList
} from "react-native";
import Hoverable from "./Hoverable";

let DATA = [
  {
    uri: require("./saburban.jpg"),
    id: "0"
  },
  {
    uri: require("./rover.jpg"),
    id: "1"
  },
  {
    uri: require("./escalade.jpg"),
    id: "2"
  },
  {
    uri: require("./niva.jpg"),
    id: "3"
  }
];

const styles = StyleSheet.create({
  leftArrow: {
    position: "absolute",
    top: "45%",
    left: "2%",
    zIndex: 2
  },
  rightArrow: {
    position: "absolute",
    top: "45%",
    right: "2%",
    zIndex: 2
  },
  arrows: { fontSize: 30, color: "white" },

  scrollView: {
    flex: 1,
    paddingVertical: "5%"
  },
  galleryContainer: {
    flex: 1,
    backgroundColor: "#999"
  },
  imageItem: {
    width: 330,
    height: 220
  },
  separator: {
    borderColor: "white",
    borderWidth: StyleSheet.hairlineWidth
  }
});

function RenderItem({ index, item, flatListRef, mobile, width }) {
  const animationScale = new Animated.Value(0);
  const animation = value => {
    Animated.timing(animationScale, {
      duration: 300,
      toValue: value
    }).start();
  };
  return (
    <View style={{ paddingHorizontal: 10 }}>
      <Hoverable
        onHoverIn={() => {
          if (!mobile) {
            flatListRef.current.scrollToIndex({ animated: true, index: index });
            animation(1);
          }
        }}
        onHoverOut={() => {
          if (!mobile) animation(0);
        }}
      >
        {isHovered => (
          <TouchableOpacity
            disabled={true}
            //activeOpacity={0.1}
            //underlayColor={"white"}
            //onPress={() => onSelect(id)}
            //onShowUnderlay={separators.highlight}
            //onHideUnderlay={separators.unhighlight}
          >
            <Animated.Image
              source={item.uri}
              resizeMode="cover"
              style={[
                styles.imageItem,
                mobile && { width: width - 20, height: (width - 20) * 0.665 },
                !mobile && {
                  opacity: animationScale.interpolate({
                    inputRange: [0, 1],
                    outputRange: [1, 0.8]
                  }),
                  transform: [
                    {
                      scale: animationScale.interpolate({
                        inputRange: [0, 1],
                        outputRange: [1, 1.1]
                      })
                    }
                  ]
                }
              ]}
            />
          </TouchableOpacity>
        )}
      </Hoverable>
    </View>
  );
}

export default function Gallery({ width, mobile }) {
  const flatListRef = useRef(null);
  const initialScrollIndex = 3;

  const keyExtractor = item => {
    return item.id;
  };

  return (
    <View style={styles.galleryContainer}>
      <FlatList
        ref={flatListRef}
        data={DATA}
        getItemLayout={(data, index) => ({
          length: 0,
          offset: 330 * index,
          index
        })}
        //initialNumToRender={3}
        contentContainerStyle={[styles.scrollView]}
        horizontal={true}
        keyExtractor={keyExtractor}
        extraData={width}
        showsHorizontalScrollIndicator={false}
        renderItem={({ item, index }) => (
          <RenderItem
            index={index}
            item={item}
            flatListRef={flatListRef}
            mobile={mobile}
            width={width}
          />
        )}
      ></FlatList>
    </View>
  );
}
