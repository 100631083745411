import React from "react";
import { StyleSheet, Text, View } from "react-native";
import Hoverable from "./Hoverable";
import { FaFacebookF, FaInstagram, FaYoutube } from "react-icons/fa";

const Link = props => (
  <Text
    {...props}
    accessibilityRole="link"
    style={StyleSheet.compose(styles.link, props.style)}
  />
);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#f0f3f5",
    paddingVertical: 60,
    paddingHorizontal: "5%"
  },
  text: {
    justifyContent: "center",
    textAlign: "center",
    padding: 10,
    fontSize: 20
  },
  socialIcons: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  authorContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center"
  },
  author: {
    justifyContent: "center",
    textAlign: "center",
    padding: 10,
    fontSize: 14,
    fontFamily: "Shadows Into Light, cursive",
    color: "rgba(0,0,0,.87)",
    lineHeight: 16
  }
});

export default function Footer({ mobile }) {
  return (
    <View style={[styles.container, mobile && { paddingVertical: 20 }]}>
      <View style={styles.socialIcons}>
        <Hoverable onHoverIn={null} onHoverOut={null}>
          {isHovered => (
            <Text style={[styles.text, mobile && { fontFamily: 14 }]}>
              {" "}
              <Link
                href="/"
                style={[
                  { color: "#0c0c0c" },
                  isHovered && { color: "#3b5998" }
                ]}
              >
                <FaFacebookF />
              </Link>
            </Text>
          )}
        </Hoverable>
        <Hoverable onHoverIn={null} onHoverOut={null}>
          {isHovered => (
            <Text style={[styles.text, mobile && { fontFamily: 14 }]}>
              {" "}
              <Link
                href="/"
                style={[
                  { color: "#0c0c0c" },
                  isHovered && { color: "#98569d" }
                ]}
              >
                <FaInstagram />
              </Link>
            </Text>
          )}
        </Hoverable>
        <Hoverable onHoverIn={null} onHoverOut={null}>
          {isHovered => (
            <Text style={[styles.text, mobile && { fontFamily: 14 }]}>
              {" "}
              <Link
                href="/"
                style={[{ color: "#0c0c0c" }, isHovered && { color: "red" }]}
              >
                <FaYoutube />
              </Link>
            </Text>
          )}
        </Hoverable>
      </View>
      <View style={styles.authorContainer}>
        <Text style={styles.author}>Developed by {"\n"}Yuriy Pashovkin</Text>
      </View>
    </View>
  );
}
