import React from "react";
import { StyleSheet, View } from "react-native";

const styles = StyleSheet.create({
  videoContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row"
  },
  videoItem: { flex: 1, alignItems: "center", padding: 10 }
});

export default function Video({ width, mobile }) {
  let frameWith = mobile ? width : width / 2 - 20;
  let frameHeight = mobile ? width * 0.6 : width / 3;
  return (
    <View
      style={[styles.videoContainer, mobile && { flexDirection: "column" }]}
    >
      <View style={styles.videoItem}>
        <iframe
          width={frameWith}
          height={frameHeight}
          src="https://www.youtube.com/embed/SLlSe38kjbc"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </View>
      <View style={styles.videoItem}>
        <iframe
          width={frameWith}
          height={frameHeight}
          src="https://www.youtube.com/embed/vObUdD2z_sA"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </View>
    </View>
  );
}
